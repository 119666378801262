import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faBirthdayCake,
    faInfoCircle,
    faCompass,
    faUsers,
} from '@fortawesome/pro-light-svg-icons'
import {
    faTwitter,
    faInstagram,
    faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons'

export function onClientEntry() {
    library.add(
        faBirthdayCake,
        faInfoCircle,
        faCompass,
        faUsers,
        faTwitter,
        faInstagram,
        faFacebookSquare
    )
}
